.produkt_konfigurator {
    .four-wrapper-header {
        @extend .four-wrapper;
        padding-top: 0;
    }

    .heading-telefonanlagen {
        margin: -30px -30px 20px -30px;
        padding: 40px 20px 20px 40px;
        color: white;
        background-image: url('../images/header/bigstockphoto-117468575.jpg');
        background-color: #555a5d;
        background-repeat: no-repeat;
        background-size: cover;
        height: 300px;

        h1 {
            margin-top: 52px;
            line-height: 40px;
            span {
                font-size: 60%;
            }
        }

        h2 {
            display: inline-block;
            font-size: 26px;
            line-height: 19px;

            padding-bottom: 10px;
            margin-bottom: 20px;
            border-bottom: 1px solid white;
        }

        p {

        }
    }

    .four-radiobutton {
        position: absolute;
        right: 12px;
        bottom: 15px;
        z-index: 5;
    }

    .four-fieldset {
        .choices {
            & > div {
                padding: 30px;
                border: 1px solid #eee;
            }
        }
        .text-center {
            text-align: center;
        }
    }

    .four-radiobutton {
        position: relative;
        cursor: pointer;
        outline: 0;
        width: 16px;
        height: 16px;
        appearance: none;
        -ms-appearance: none;
        -moz-appearance: field;
        -webkit-appearance: none;
        -webkit-transition: all 1.25s ease-in-out;
        -moz-transition: all 1.25s ease-in-out;
        -o-transition: all 1.25s ease-in-out;
        transition: all 1.25s ease-in-out
    }

    .four-radiobutton:focus {
        outline: none !important
    }

    .four-radiobutton:before,
    .four-radiobutton:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-width: 1px;
        border-style: solid;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        outline: none;
        -webkit-transition: all 0.25s cubic-bezier(0.45, 1.8, 0.5, 0.75);
        -moz-transition: all 0.25s cubic-bezier(0.45, 1.8, 0.5, 0.75);
        -o-transition: all 0.25s cubic-bezier(0.45, 1.8, 0.5, 0.75);
        transition: all 0.25s cubic-bezier(0.45, 1.8, 0.5, 0.75)
    }

    .four-radiobutton:before {
        z-index: 3;
        border-color: #333;
        background-color: #fff
    }

    .four-radiobutton:after {
        z-index: 4;
        border-color: #090;
        background-color: #090;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0)
    }

    .four-radiobutton:checked {
        outline: none
    }

    .four-radiobutton:checked:before,
    .four-radiobutton:checked:after {
        outline: none
    }

    .four-radiobutton:checked:before {
        border-color: #333
    }

    .four-radiobutton:checked:after {
        -webkit-transform: scale(0.6);
        -moz-transform: scale(0.6);
        -ms-transform: scale(0.6);
        -o-transform: scale(0.6);
        transform: scale(0.6)
    }

    .four-radiobutton:checked:hover:after {
        -webkit-transform: scale(0.6);
        -moz-transform: scale(0.6);
        -ms-transform: scale(0.6);
        -o-transform: scale(0.6);
        transform: scale(0.6)
    }

    .four-radiobutton:checked+.four-radiobutton-firefox,
    .four-radiobutton:checked+label+.four-radiobutton-firefox {
        background-position: -152px -7px
    }

    .four-radiobutton:hover:after {
        -webkit-transform: scale(0.25);
        -moz-transform: scale(0.25);
        -ms-transform: scale(0.25);
        -o-transform: scale(0.25);
        transform: scale(0.25)
    }

    @-moz-document url-prefix() {
        .four-radiobutton {
            opacity: 0
        }
        .four-radiobutton-firefox {
            display: inline-block;
            width: 16px;
            height: 16px;
            background-image: url("/bundles/appres/img/sprite-switch-icons.png");
            background-position: -122px -7px;
            background-repeat: no-repeat;
            cursor: pointer;
            pointer-events: none
        }
    }

}



