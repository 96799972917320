/* icons: http://www.flaticon.com/packs/material-design */

@mixin theme($name, $color-primary, $color-primary-hover) {
  .#{$name} {

.four-wrapper {
    @extend %clearfix;
    /*border-bottom: 1px solid $spacer-line-color;*/
    position: relative;
    background-color: white;
    padding: 30px;
    margin-bottom: 15px;

    -webkit-box-shadow: 0px 0px 29px 2px rgba(69,69,69,0.25);
    -moz-box-shadow: 0px 0px 29px 2px rgba(69,69,69,0.25);
    box-shadow: 0px 0px 29px 2px rgba(69,69,69,0.25);

    clear: both;
}


.page-content-wrapper {

    font-size: 15px;

    h1,h2,h3,h4,h5,h6 {
        font-family: $brand-font;
        font-weight: normal;
    }
    h1 {
        font-family: $blender-heavy;
        font-size: 50px;
        line-height: 48px;
        text-transform: uppercase;
        margin-top: 0;

        .material-icons {
            font-size: 40px;
            padding: 5px 20px 0 0;
        }
    }
    h2 {
        font-family: $blender-heavy;
        font-size: 40px;
        line-height: 38px;
        text-transform: uppercase;
        margin-top: 0;

        .material-icons {
            font-size: 40px;
            padding: 5px 20px 0 0;
        }
    }
    h3 {
        font-size: 25px;
        text-transform: uppercase;
        margin-top: 0;
    }
    h4 {
        font-size: 21px;
        /*text-transform: uppercase;*/
    }
    img {
        max-width: 100%;
    }

    ol {
        padding-left: 19px;
    }
    ul {
        padding-left: 0;

        li {
            list-style-type: none;
            padding-left: 22px;
            margin-left: 0px;
            background-image: url('../../assets/images/checkmark.svg');
            background-repeat: no-repeat;
            background-position: 0 5px;
            background-size: 13px;
            margin-bottom: 5px;
        }
        &.arrow li {
            padding-left: 20px;
            background-image: url('../../assets/images/arrow-right.svg');
        }
    }
    input, button, textarea, label, .radio-container span {
        font-size: 13px;
        font-weight: normal;
        margin: 5px;
    }
    input[type="text"], textarea {
        outline-color: $color-primary;
        &.form-error {
            color: $brand-warn !important;
            border-color: $brand-warn !important;
            outline-color: $brand-warn !important;
            background-color: $brand-warn-background !important;
        }
    }
    input, textarea {
        background-color: $white;
        border: none;
        color: $brand-secondary;
        padding: 8px;
    }
    button {
        background-color: $brand-secondary;
        color: $white;
        border: none;
        padding: 8px 15px;
        &:active, &.processing {
            cursor: not-allowed;
        }
        &:hover {
            background: $brand-secondard-hover;
        }
        &.button-green {
            background-color: $color-primary;

            &:hover {
                background: $color-primary-hover;
            }
        }
    }

    & > div {

        &.nopadding-lr {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .clearfix {
        @extend %clearfix;
    }
    .text-green, .material-icons {
        color: $color-primary;
    }
    .text-gray, .material-icons.gray {
        color: $brand-secondary;
    }
    .text-white, .material-icons.white {
        color: #FFF;
    }
    .center {
        text-align: center;
    }
    .float-right {
        float: right;
    }
    .float-left {
        float: left;
    }
    .box-center {
        margin: 0 auto;
    }
    .margin-top {
        margin-top: 10px;
    }
    .nopaddingright {
        padding-right: 0 !important;
    }
    .nopadding {
        padding: 0;
    }
    .inlineblock {
        display: inline-block;
    }
    a.button {
        display: inline-block;
        padding: 10px 20px;
        text-decoration: none;

        &.button-green {
            color: white;
            background: $color-primary;
            font-weight: 500;
            font-size: 0.9em;
            &:hover {
                background-color: $color-primary-hover;
            }
        }
        &.button-gray {
            color: white;
            background: $brand-secondary;
            font-weight: 500;
            font-size: 0.9em;
            &:hover {
                background: $brand-secondard-hover;
            }
        }
    }
    input[type="submit"] {
        @extend a.button;
        @extend a.button.button-green;
        @extend .float-right;
        outline: none;
        cursor: pointer;
    }

    .pageheading.four-wrapper {
        max-height: 600px;
        padding-top: $navbar-second-height;
        background-repeat: no-repeat;
        background-position: top center;
        background-image: url('../../assets/images/header-bg.jpg');
        background-size: cover;
        border-bottom: 5px solid $color-primary;
        img {
            max-height: 330px;
            float: right;
        }
        @include respond-to(xs) {
            max-height: none;
        }
        @include respond-to(sm) {
            max-height: none;
        }
    }
    
    .image-columns, .drei_oder_vierspaltig {
        
        h1, h2 {
            padding-bottom: 30px;
        }
        h3 {
            color: $color-primary;
        }

        .image_container {
            min-height: 0px;
            text-align: center;
            margin-bottom: 25px;
            &.noimage {
                margin-bottom: 0px;
            }
        }

        & > div {

            a.button {
                margin-top: 20px;
            }
        }

        @include respond-to(xs){
            & > div:not(:first-child) {
                margin-bottom: 70px;
                &:last-child {
                    margin-bottom: 10px;
                }
            }
        }

    }

    .erreichbarkeit {
        
        .stoerer-rechts {
            @extend .clearfix;
            background-color: $color-primary;
            display: inline-block;
            position: absolute;
            bottom: 30px;
            right: 0px;
            padding: 15px 45px 15px 25px;
            & > div {
                font-size: 33px;
                line-height: 22px;
                display: inline-block;
                float: left;
                color: white;
            }
            .material-icons {
                padding-right: 10px;
            }

        }
    }

    .xdslcheck {
        background-color: $color-primary;
        /*left: -10px;*/

        h1 {
            margin-top: 0px;
            font-size: 30px;
            text-transform: uppercase;
        }

        input, button {
            width: 100%;
            margin: 0;
            margin-top: 5px;
        }
        /*
        &:before {
            border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #9AAF01 rgba(0, 0, 0, 0);
            border-style: solid;
            border-width: 0 0 10px 10px;
            content: "\0020";
            height: 0;
            left: 0px;
            position: absolute;
            top: -10px;
            width: 0;
        }
        &:after {
            border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #9AAF01;
            border-style: solid;
            border-width: 10px 0 0 10px;
            content: "\0020";
            height: 0;
            right: 0px;
            position: absolute;
            top: -10px;
            width: 0;
        }
        */
        @extend %clearfix;
    }

    .preisliste {
        @extend %clearfix;

        .land {
            padding-top: 20px;
        }
        .land-content {
            display: inline-block;
        }

        .flag {
            /*width: 90px;*/
            height: 51px;
            float: left;
            padding-right: 15px;
            background-repeat: no-repeat;
            background-size: contain;
        }
        .country {
            font-size: 20px;
            line-height: 22px;
            font-weight: 300;
        }
        .preis_box {
            display:inline-block;
            float:left;
            width: 40%;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
        .preis_cent {
            font-size: 32px;
            line-height: 34px;
            font-weight: 100;
        }
        .desc_small {
            font-size: 11px;
            line-height: 21px;
            color: #969696;
            text-align: left;
            padding-left: 2px;
        }
        .download-prices {
            margin-top: 30px;
            img {
                max-height: 17px;
                padding: 0 10px;
                margin-top: -4px;
            }
            a {
                font-size: 17px;
                line-height: 17px;
                vertical-align: middle;
            }
        }
    }

    .kontakt {
        /* input containers */
        form .row > div {
            padding: 5px;
        }
        .radio-container .wpcf7-list-item {
            cursor: pointer;
        }
        .radio-container label {
            *, span, input {
               cursor: pointer; 
            }

            span.wpcf7-list-item-label:before {
                position: absolute;
                top: 10px;
                font-family: 'Material Icons';
                font-weight: normal;
                font-style: normal;
                font-size: 18px;
                line-height: 1;
                letter-spacing: normal;
                text-transform: none;
                display: inline-block;
                white-space: nowrap;
                word-wrap: normal;
                direction: ltr;
                color: rgba(0,0,0,0.3);
                display: block;
                width: 24px;
                height: 24px;
                margin-left: -14px;
                background-repeat: no-repeat;
                background-size: 16px;
                border: none;
                content: "";
                background-image: url('../../assets/images/flat-circle.svg');
            }
            & input:checked+span.wpcf7-list-item-label:before {
                background-image: url('../../assets/images/flat-circle-checked.svg'); 
            }
        }
        input {
            /*margin: default;*/
            &[type="text"], &[type="tel"], &[type="email"] {
                width: 100%;
                border: 1px solid $spacer-line-color;
                margin: 0;
            }
            &[type="radio"] {
                opacity: 0;
                position: absolute!important;
                height: 0;
                width: 0;
                border: 0;
                padding: 0;
                margin: 0;
            }
        }
        textarea {
            width: 100%;
            min-height: 120px;
            border: 1px solid $spacer-line-color;
            margin: 0;
        }
        .wpcf7 {
            margin-top: 45px;
        }
        span.wpcf7-not-valid-tip {
            color: #EC6119;
            font-size: 13px;
            /*color: $notification-color;*/
        }
        div.wpcf7-validation-errors {
            border: 2px solid #FF8442;
            margin: 1em -10px;
            padding: 3px 5px;
        }
        div.div.wpcf7-mail-sent-ok {
            border: 2px solid $color-primary;
            margin: 1em -10px;
            padding: 3px 5px;
        }
        .screen-reader-response {
            display: none;
        }
    }

    
    }
    /* end of #wrapper */
  }
}

@include theme(theme-green, $brand-primary, $brand-primary-hover);
@include theme(theme-red, $red-primary, $red-primary-hover);
@include theme(theme-blue, $blue-primary, $blue-primary-hover);
// @include theme(theme-gray, $gray-primary, $gray-primary-hover);
