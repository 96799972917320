// **************************************************************************** //
//                                  Top Menu                                    //
// **************************************************************************** //

$navbar-super-height: 62px;
$navbar-second-height: 50px;
$navbar-super-border-bottom: 5px solid $brand-primary;

$navbar-super-nav-active-color: $brand-primary;
$navbar-super-nav-hover-color: $brand-primary;
$nav-item-background: transparent;
$navbar-super-nav-active-addon-background: orange;

$nav-triangle-size: 6px;

.no-padding-right {
  padding-right: 0 !important;
}

#page-content-wrapper {
    /*padding-top: $navbar-super-height;*/
    padding-top: 62px;

    &.has-submenu {
        padding-top: 135px;
    }

    @include respond-to(xs) {
        padding-top: 60px;
    }
}

.navbar-super-up .navbar-super-holder {
    top: -$navbar-super-height;
}

.navbar-super-holder {
    background-color: white;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1001;
    transition: .4s;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;
}

.navbar-super {
    height: $navbar-super-height !important;
    z-index: 1000;
    margin: none;
    background: white;

    .brand {
      img {
        width: 100%;
        max-width: 200px;
        padding-top: 1.4em;
      }
    }

    a.domain:hover{
        color: white;
    }

    &-down .main {
        .back-to-top {
            opacity: 0;
        }
    }

    &-up .main {
        .back-to-top {
            opacity: 1 !important;
        }
    }

    /*ul.sub-menu {
        display: none;
    }*/
}

.nav-hidden {
    display: none;
}

.nav-super{

    .account-dropdown{
        cursor: pointer;
        .dropdown-menu{
            left:0;
            width: auto;
            min-width: fit-content;
            color: black;
            &>li{
                padding: 10px;
                &>a, &>span{
                    padding: 0;
                }
            }
            .username{
                color: $brand-secondary;
            }
            &:hover{
                background-color: white;
            }
        }
    }
    li a{
        height: auto;
        text-decoration: none;
    }
}

// MENU
.nav {

    li {
        background: transparent;
        background-color: transparent;
        font-size: 15px;
        float: left;
        list-style-type: none;

        a, span {
            display: block;
            padding: 1.30em 1em;
            text-decoration: none;
            color: $black;
            font-weight: 500;
            font-size: 15px;
            line-height: $line-height-base;

            span {
                display: inline;
                margin: 0;
                padding: 0;
            }

            &:hover, &:focus {
                color: $navbar-super-nav-hover-color;
                background: $nav-item-background;
            }

            // .transition(all, .2s, ease-in-out);
        }

        &.active {
            & > a {
                color: $navbar-super-nav-active-color;
                background: $nav-item-background;
                position: relative;
            }
        }

        &:hover, &:focus {
            & > a {
                color: $navbar-super-nav-hover-color;
                background: $nav-item-background;
            }
        }
    }

    &.nav_right {
        display: inline-block;
        float: right;

        li {
            &:after {
                content: "|";
                position: absolute;
                right: -0.1em;
                top: 1.75em;
                font-size: 12px;
            }
            &:last-child:after {
                content: "";
            }

            a, span {
                padding: 1.50em 0.4em 1.20em;
                font-size: 13px;
            }
        }
    }
}
.has-submenu .nav li a.active a:before {
    content: '';
    display: block;
    position: absolute;
    bottom: -$nav-triangle-size;
    height: $nav-triangle-size;
    width: 0;
    height: 0;
    left: 50%;
    margin-left: -($nav-triangle-size);
    border-style: solid;
    border-width: $nav-triangle-size $nav-triangle-size 0 $nav-triangle-size;
    border-color: #fff transparent transparent transparent;
}

nav#nav-second-holder {

    padding-top: $nav-triangle-size;

    ul.sub-menu {
        li {
            background: $navbar-second-background;
            list-style-type: none;
            line-height: 16px;
            padding: 16px 9px 22px; // $nav-triangle-size
            margin: 0;
            display: inline-block;
            float: left;

            a {
                text-decoration: none;
            }

            &:not(.active) {
                a {
                    color: white;
                }
            }
            &:hover {
                a {
                    color: $navbar-super-nav-hover-color;
                }
            }
            &:first-child {
                padding-left: 0;
            }
        }
    }
}
.navbar-second {
    z-index: -1;
    background: $navbar-second-background;
    
    left: 0;
    line-height: 1.42857143;
    top: $navbar-super-height;
    animation: SecondNavSlideDown .3s linear;

    margin: none;
    .nav {
        // .default-nav;
    }
    .user-part {
        float: right;

        .signin-button {
            cursor: pointer;
            float: right;
        }

        .username {
            cursor: default;
            display: block;
            text-decoration: none;
        }
    }
    .sub-menu {
        margin: 0;
        padding: 0;
        padding-left: 15px;

        #menu-button {
            border: none;
            background: none;
            padding: 10px;
            height: $navbar-second-height;
            //.sidebar-transition;
            &.active .icon-bar {
                background-color: $white;
            }
            //.sidebar-transition;
            @media (min-width: 741px) {
                display: none;
            }
            .icon-bar {
                background-color: $brand-primary;
                display: block;
                width: 22px;
                height: 2px;
                border-radius: 1px;
            }
            .icon-bar+.icon-bar {
                margin-top: 4px;
            }
        }
    }

    @media (max-width: 740px) {
        .nav {
            display: none;
        }
    }
}


.navbar-super-up {
    #sidebar {
        margin-top: 0;
    }
}

//*****************   Mobile Navigation  *******************//
// only on DESKTOP
.nav-primary {
    ul li ul {
        display: none;
    }
}

@include respond-to(xs){
    .nav-primary {
        /*& > ul > li {
            &.active, &:hover, &:active {
                a {
                    color: #999;
                }
            }
        } */
        ul li ul {
            display: block;
            padding-left: 15px;
        }
        ul {
            display: block;
            padding-left: 15px;

            li {
                float: none;
                a, span {
                    padding: 0.4em 0em;
                }
            }
        }
    }
    .navbar-second {
        display: none;
    }
}

.navbar-toggle {
    margin-right: 5px;
    padding: 9px 10px 9px;
    margin-top: 13px;
    margin-bottom: 8px;
    .icon-bar {
        background-color: #3E3E3E;
    }
}

//*****************   Animation for navigation   ****************//
.navbar-super-holder {
    border-bottom: $navbar-super-border-bottom;
    a {
        -webkit-animation:fadeIn 1s;
        //Firefox fix
        animation-duration: 1s;
        animation-name: fadeIn;
    }
}
@keyframes SecondNavSlideDown {
    0% {transform: translate(0, -30px);}
    100% {transform: translate(0, 0);}
}

#header {
  .top-navs { // container class
    ul { // container class first unordered list
      ul { //unordered list within an unordered list
      }
    }
    li { // each navigation item
      a { // each navigation item anchor
      }
      ul { // unordered list if there is drop down items
      }
      li { // each drop down navigation item
        a { // each drap down navigation item anchor
        }
      }
    }
  }
}

