footer {
    padding-bottom: 40px;
    span, a, div {
        color: #FFFFFF;
        text-shadow: 1px 1px #000;
    }
    .nav_footer {
        display: inline-block;
        float: right;
        $footer_link_color: #FFF;

        li {
            &:after {
                color: $footer_link_color;
                content: "|";
                position: absolute;
                right: -0.1em;
                top: 0em;
                font-size: 12px;
            }
            &:last-child:after {
                content: "";
            }

            a, span {
                color: $footer_link_color;
                padding: 0em 0.4em;
                font-size: 13px;
                font-weight: normal;
            }
        }
    }
}

#disclaimer_bar a {
    @extend a.button;
    @extend a.button.button-green;
    @extend .float-right;
}
