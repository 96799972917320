// Glyphicons font path
$icon-font-path:        "../fonts/";

$brand-font: "Blender-Bold", "Lucida Console", Monaco, monospace;
$blender-heavy: "Blender-Heavy", "Lucida Console", Monaco, monospace;
$blender-bold: "Blender-Bold", "Lucida Console", Monaco, monospace;
$blender-medium: "Blender-Medium", "Lucida Console", Monaco, monospace;

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #b1c903;
$brand-primary-hover:   #BDD226;
$brand-secondary:       #828479;
$brand-secondard-hover: #989A8E;
$black:                 #585858;
$white:                 #FFF;
$navbar-grey:           #82837b;

// Farbschemas
$red-primary: #eb493d;
$red-primary-hover: #ec5b51;

$blue-primary: #1895d7;
$blue-primary-hover: #22a2e5;

// $orange-primary: #F38838;
// $orange-primary-hover: #F59E5D;

// General 
$spacer-line-color:           #D6D6D6;

$navbar-second-background: #887f74;

// error and warning colors
$brand-warn: #FF8F00; // orange
$brand-warn-background: #FFF9F1; // light-orange


//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              $black; // lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

$text-color:            $black;


%clearfix {
  *zoom: 1;
  &::before,
  &::after {
    content: " ";
    display: table;
  }

  &::after {
    clear: both;
  }
}