@mixin theme($name, $color-primary, $color-primary-hover) {
  .#{$name} {

.wizard {

    ul.registersteps {
        background-color: #fff;
        height: 60px;
        margin-bottom: 0px;
        overflow: hidden;
        position: relative;
        width: 100%;
        list-style: none;

        li {
            color: #999999;
            float: left;
            font-weight: bold;
            height: 60px;
            position: relative;
            width: 25%;
            padding: 20px 0 20px 29px;
            font-size: 14px;
            background: transparent;
            
            // & i {
            //     background: url(../images/sprite.png) no-repeat -89px 0 transparent;
            //     height: 20px;
            //     position: absolute;
            //     right: 25px;
            //     top: 17px;
            //     width: 22px
            // }
            // & i:before {
            //     content: "\0020"
            // }
            &:before,
            &:after {
                border-color: transparent transparent transparent $color-primary;
                border-style: solid;
                border-width: 36px 0 36px 17px;
                content: "\0020";
                display: block;
                height: 0;
                line-height: 0px;
                position: absolute;
                right: 0;
                top: -6px;
                width: 0;
            }
            &:after {
                border-color: transparent transparent transparent #fff;
                right: 3px;
            }
            // &:first-child {
            //     width: 262px
            // }
            // &:last-child {
            //     width: 242px
            // }
            &:last-child:before,
            &:last-child:after {
                border: 0 none;
            }
            &.visited,
            &.current {
                background-color: lighten( $color-primary, 1% );
                color: #fff;
            }
            &.visited:before,
            &.current:before {
                border-color: transparent transparent transparent #fff
            }
            &.visited:after,
            &.current:after {
                border-color: transparent transparent transparent lighten( $color-primary, 1% )
            }
            // &.visited i,
            // &.current i {
            //     background: url(../images/sprite.png) no-repeat -26px -34px transparent;
            //     height: 20px;
            //     position: absolute;
            //     right: 25px;
            //     top: 17px;
            //     width: 22px
            // }
            // &.visited i:before,
            // &.current i:before {
            //     content: "\0020"
            // }
            &.current:after {
                background-color: #fff;
                right: 0px;
            }
            // &.current i {
            //     background: url(../images/sprite.png) no-repeat -89px 0 transparent;
            //     height: 15px;
            //     right: 30px;
            //     top: 22px;
            //     width: 15px
            // }
            & a {
                color: #fff;
                display: block;
                left: 0;
                padding: 21px 0 20px 22px;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 100;
            }
            &:first-child a {
                padding-left: 37px;
            }
        }
    }
}
}
}
@include theme(theme-green, $brand-primary, $brand-primary-hover);
@include theme(theme-red, $red-primary, $red-primary-hover);
@include theme(theme-blue, $blue-primary, $blue-primary-hover);