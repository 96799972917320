@font-face {
    font-family: 'Blender-Bold';
    src: url('../fonts/Blender-Bold.eot');
    src: local('☺'), url('../fonts/Blender-Bold.woff') format('woff'), url('../fonts/Blender-Bold.ttf') format('truetype'), url('../fonts/Blender-Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Blender-Book';
    src: url('../fonts/Blender-Book.eot');
    src: local('☺'), url('../fonts/Blender-Book.woff') format('woff'), url('../fonts/Blender-Book.ttf') format('truetype'), url('../fonts/Blender-Book.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Blender-Heavy';
    src: url('../fonts/Blender-Heavy.eot');
    src: local('☺'), url('../fonts/Blender-Heavy.woff') format('woff'), url('../fonts/Blender-Heavy.ttf') format('truetype'), url('../fonts/Blender-Heavy.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Blender-Medium';
    src: url('../fonts/Blender-Medium.eot');
    src: local('☺'), url('../fonts/Blender-Medium.woff') format('woff'), url('../fonts/Blender-Medium.ttf') format('truetype'), url('../fonts/Blender-Medium.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Blender-Thin';
    src: url('../fonts/Blender-Thin.eot');
    src: local('☺'), url('../fonts/Blender-Thin.woff') format('woff'), url('../fonts/Blender-Thin.ttf') format('truetype'), url('../fonts/Blender-Thin.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

%material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
