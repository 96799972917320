
$breakpoints: (
  'xs'  : ( max-width:  767px ),
  'sm'  : ( min-width:  768px ),
  'md' : ( min-width:  992px ),
  'lg'  : ( min-width: 1200px )
);
 
@mixin respond-to($name) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $name) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $name))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoints}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}